@import url('https://fonts.googleapis.com/css2?family=Asset&family=Londrina+Shadow&family=Major+Mono+Display&family=Monoton&family=Mrs+Sheppards&family=Rowdies:wght@700&family=Russo+One&display=swap');


.header{
  font-family: 'Rowdies', cursive;
  h1{
    font-size: 2.2em;
    
    text-shadow: 3px 3px white;
    background-image: url(/public/assets/title.webp);
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    
   
    margin: 10px 0;
  }
 

}


