@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family: 'Rowdies';
  src: url("../public/fonts/Rowdies-Regular.ttf")
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;
  background-image:
  radial-gradient(
      #762a99,
      #7c0747
  );
}


